import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = [ 'cardElement', 'cardErrors', 'form', 'spinner', 'subscriptionForm', 'url' ]
    static values = {
    	handler: String
    }

    connect() {
        var stripe = Stripe(this.data.get('key'));
		var elements;
		
		this.stripe = stripe;
		this.elements = elements;
		
		var spinner = this.spinnerTarget;
        var form = this.formTarget;
        var displayError = this.cardErrorsTarget;
        const applyLabel = this.displayLabel;

        // Handle payment form submission.
        if (this.data.get('handlerValue') == "pay") {
        	elements = stripe.elements();
        	var style = {
				base: {
			        color: '#32325d',
			        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
			        fontSmoothing: 'antialiased',
			        fontSize: '16px',
			        '::placeholder': {
			            color: '#aab7c4'
			        }
			    },
			    invalid: {
			        color: '#EE6352',
			        iconColor: '#EE6352'
			    }
			};
			
			var $card = elements.create('card', {
			    style: style
			});
			$card.mount(this.cardElementTarget);
			
			// Handle real-time validation errors from the card Element.
	        $card.addEventListener('change', function (event) {
	            if (event.error) {
	                displayError.textContent = event.error.message;
	            } else {
	                displayError.textContent = '';
	            }
	        });

			form.addEventListener('submit', function (event) {
	            event.preventDefault();
				spinner.classList.toggle('d-none');
				
				stripe.confirmCardPayment(form.dataset.stripeElementsSecret, {
					payment_method: {
						card: $card,
				    }
			    }).then(function(result) {
				    spinner.classList.toggle('d-none');
				    if (result.error) {
				    	this.displayLabel(displayError, 'danger', result.error.message);
				    } else {
				    	// The payment has been processed!
				    	if (result.paymentIntent.status === 'succeeded') {
				    		this.displayLabel(displayError, 'success', "Your payment was successfully submitted!");
				        }
			    		form.submit();
		        	}
		    	});
	        });
        } else if(this.data.get('handlerValue') == "subscribe") {
        	const options = {
        		mode: 'subscription',
        		amount: 1000,
	    		currency: 'usd',
				captureMethod: 'automatic',
				setupFutureUsage: 'off_session',
        		paymentMethodTypes:	['card'],
        		appearance: {
        			labels: 'floating',
        		},
			};
			
			elements = stripe.elements(options);
			const paymentElement = elements.create('payment');
        	paymentElement.mount('#payment-element');
        	
        	paymentElement.on('ready', ()=> {
				paymentElement.on('change', function(event) {
					if (event.complete) {
				    	this.cardErrorsTarget.setAttribute('class', `col`);
				    	this.cardErrorsTarget.textContent = '';
			    	}
				});
        	});
			
			[...document.querySelectorAll('.user_venue'), ...document.querySelectorAll('.subscription_plan')].forEach(item => {
				item.addEventListener('click', event => {
					this.cardErrorsTarget.classList.toggle('d-none');
				})
			});
				
				
			form.addEventListener('submit', async (event) => {
				event.preventDefault();
        
		        this.spinnerTarget.classList.toggle('d-none');
		
				let intent = this.urlTarget.dataset.intent;
				let url = this.urlTarget.dataset.url;
				let sub_id = this.urlTarget.dataset.subId;
				let payment_id = this.urlTarget.dataset.paymentId;
				let priceId = document.querySelector("input[name='subscription']:checked");
				let card_on_file = (document.querySelector("input[name='card_on_file']:checked")) ? document.querySelector("input[name='card_on_file']:checked").value : false;
				let venues_array = document.querySelectorAll("input[name='venues[]']:checked");
				let venues = [];
				for(let v of venues_array){
					venues.push(v.value);
				}
				
				if (venues.length == 0) {
				    this.displayLabel(this.cardErrorsTarget, 'danger', 'No venues selected');
				    this.spinnerTarget.classList.toggle('d-none');
				    //return false;
				    throw new Error(`No venues selected`);
			    } 
		    	
		    	if (!priceId) {
				    this.displayLabel(this.cardErrorsTarget, 'danger', 'No subscription plan selected');
				    this.spinnerTarget.classList.toggle('d-none');
				    //return false;
				    throw new Error(`No subscription plan selected`);
			    } 
		    	
		    	
		    	let allOpts = {
					amount: parseInt(priceId.value),
				};
				
				//Add subscription fee to the Stripe elements object  
				elements.update(allOpts);
		        
				if (!card_on_file) {
					// Trigger form validation and wallet collection
			        const {error: submitError} = await elements.submit();
			        if (submitError) {
				    	this.displayLabel(this.cardErrorsTarget, 'danger', submitError.message);
				    	 this.spinnerTarget.classList.toggle('d-none');
			    	}
				} else {
					paymentElement.update({readOnly: true, fields: {billingDetails: 'never'}});
				}
				
				if (!payment_id) {
					stripe.createPaymentMethod({elements})
					.then(function(result) {
					    // Handle result.error or result.paymentMethod
				    	if (result.error){
				    		throw new Error(`Attempt tp create PaymentMethod failed: ${result.error}`);
				    	} else {
				    		payment_id = result.paymentMethod.id
				    	}
					});
				}
				// Process the PaymentIntent		
		    	const csrfToken = document.querySelector("[name='csrf-token']").content;
		    	const res = await fetch(url, {
				    method: "POST",
				    headers: {
				    	"X-CSRF-Token": csrfToken,
				    	"Content-Type": "application/json",
				    },
			  		body: JSON.stringify({
			  			card_on_file: card_on_file,
			  			priceId: priceId.dataset.priceId,
			  			amount: priceId.value,
			  			payment_id: payment_id, 
			  			sub_id: sub_id,
			  			venues: venues,
					})
	  			});
			    const {type, clientSecret} = await (res.json());
			    const confirmIntent = type === "setup" ? stripe.confirmSetup : stripe.confirmPayment;
			    
			    console.log(`Client secret from fetch call is: ${clientSecret}`)
			    // Confirm the PaymentIntent using the details collected by the Payment Element
			    let new_card_opts = {
				    elements,
				    clientSecret,
				    confirmParams: {
				      return_url: this.formTarget.action,
				    }
			    };
			    let saved_card_opts = {payment_method: payment_id, confirmParams: { return_url: this.formTarget.action}};
			    
			    const {error} = (card_on_file) ? await confirmIntent(clientSecret, saved_card_opts) : await confirmIntent(new_card_opts);
			    
			    if (error) {
				    // This point is only reached if there's an immediate error when
				    // confirming the payment. Show the error to your customer (for example, payment details incomplete)
				     this.spinnerTarget.classList.toggle('d-none');
				     this.displayLabel(this.cardErrorsTarget, 'danger', `${error.message}: ${error.param} (${error.code})`);
			    } else {
				    // Your customer is redirected to your `return_url`. For some payment
				    // methods like iDEAL, your customer is redirected to an intermediate
				    // site first to authorize the payment, then redirected to the `return_url`.
				    this.spinnerTarget.classList.toggle('d-none');
				    this.urlTarget.disabled = true;
				    this.displayLabel(this.cardErrorsTarget, 'success', "Subscribed successfully!");
				    setTimeout(() => window.location.assign(this.formTarget.action),5000);
			    }
			});
        }
    }

}