import { Controller } from "@hotwired/stimulus"
//import PlacesAutocomplete from "stimulus-places-autocomplete"

const apiTokenValue = 'AIzaSyBCqw2Pi06jksl2yA_JD2qu5v7CeXtl8wI'

export default class extends Controller  {
  static targets = [ "field" ]
  
  connect() {
    // Load Places if Google Maps API initializes before Stimulus controller  
    console.log('Places controller connect callback initiated')
    if(window.google) {
      console.log('Places API pre-loaded')
      this.initGoogle();
    }
  }

  initGoogle() {
    // GoRails //
    this.autocomplete = new google.maps.places.Autocomplete(this.fieldTarget)
    this.autocomplete.setComponentRestrictions({
      country: ["us", "pr", "vi"],
    })
    this.autocomplete.setTypes(['(cities)']);
    this.autocomplete.setFields(['address_components', 'geometry', 'name'])
    this.autocomplete.addListener('place_changed', this.placeChanged.bind(this))
    console.log('Places controller Autocomplete API initiated')
  }
  
  placeChanged() {
    let place = this.autocomplete.getPlace()
    console.log(place)

    if (!place.geometry) {
      window.alert(`No details available for input: ${place.name}`)
      return
    }
  }
  
  keydown(event) {
    if (event.key == "Enter") {
      event.preventDefault()
    }
  }
}
