import starHalf from '../../images/PNG/star-half.png';
import starOff from '../../images/PNG/star-off.png';
import starOn from '../../images/PNG/star-on.png';

$(document).on('turbo:load', function() {
	let pathname = window.location.pathname;
	// Hide query fields, all accordion body panels & error notification div by default
	$("[id$='_container']").hide();
	
	window.onLoad = $("#global_seek").trigger("reset");

	// Remove select Trix toolbar buttons from the DOM to prevent access by default
	let trix_buttons = [".trix-button-group--file-tools",".trix-button--icon-link",".trix-button--icon-heading-1",".trix-button--icon-code",".trix-button--icon-decrease-nesting-level", ".trix-button--icon-increase-nesting-level"]
	for (let button of trix_buttons) {
		$(button).remove();	
	}

	$('#find-btn').click(function(event) {
		event.preventDefault();
		const default_border = 'rgba(0,0,0,0.125)';
		const default_borderwidth = '1px';
		
		if ($('#finder-fields').hasClass('d-none')) {
			//$('#query-fields').toggleClass('d-none');
			$('#finder-fields').toggleClass('d-none animate__animated animate__fadeIn animate__zoomIn');
		} else {
			if (!$('#query-fields').hasClass('d-none')) {
				$('#query-fields').animate({height: ['toggle','swing']}, 500).addClass('d-none');
				$('#query-fields').addClass('d-none')
				$("[id$='-icon']").css('border-color', default_border);
				$("[id$='-icon']").css('border-width', default_borderwidth);
			}
			$('#finder-fields').addClass('animate__slideOutUp');
			setTimeout(() => {
				$('#finder-fields').toggleClass('d-none animate__animated animate__fadeInDown animate__slideOutUp');
			}, 50);
		}
		
	});
	
	$("[id$='_toggle']").mouseover(function() {
		this.style.cursor = "pointer";
	});
	
	$('.dropLink').click(function () {
		let ref = $(this).attr('href')
		window.location = (ref);
	});
	
	// Hide notifier if search option is selected
	$("[name='opt']").change(function() {
		if ($("[name='opt']").is(":checked") &&  $('#notifier').is(':visible')) {
			return $('#notifier').slideUp('fast', () => $('#notifier').hide());
		}
	});

	
	//Hide image attachments in form views
	$("[id^='rm_']").click(function() {
		let regexp = $(this).attr('id').match(/_(\w+)$/);
		let target = regexp[1];
		$('[id$="'+target+'"]').hide();
		
		return event.preventDefault();
	});

	
	//display star rating
	
	$('.review-rating').raty({
		readOnly: true,
		hints: ['Poor', 'Amateur', 'Average', 'Pro', 'Superstar'],
		starOn: starOn, 
		starOff: starOff,
    	starHalf: starHalf,
    	score: function() {
			console.log($(this).attr('data-score'));
			return $(this).attr('data-score');
		},
	});

	$('#rating-form').raty({
		starOn: starOn, 
		starOff: starOff,
    	hints: ['Poor', 'Amateur', 'Average', 'Pro', 'Superstar']
	});
	

	// START: Read more toggle
	// Configure/customize these variables.
    const showChar = 200;  // How many characters are shown by default
    const ellipsestext = "...";
    const moretext = "Read more";
    const lesstext = "Read less";
    

    $('.text-blurb').each(function() {
        let content = $(this).text();
        let raw = $(this).html();
        var html
 
        if(content.length > showChar) { // Truncate by character count
            let brief = raw.substring(0,showChar);
            html = `<span class="">${brief}<span class="moreellipses">${ellipsestext}&nbsp;</span></span><a href="" class="morelink plainLink">${moretext}</a></span>`;
            
            $(this).html(html);
        }
        
        $(this).click(function(){
	        if($('.morelink').hasClass("less")) {
	            $('.morelink').removeClass("less");
	            $('.morelink').html(moretext);
	        	$(this).html(html);
	        } else {
	            $(this).html(`${raw}`)
	            $(this).append(`<a href="" class="morelink plainLink less">${lesstext}</a></span>`);
	        }
	        
	        return false;
	    });
    });
    // END: Read more toggle

    $("#footer").css('height', $("#content_well").height() + 50);
	$(window).resize(() => $("#footer").css('height', $("#content_well").height() + 50));
});