$(document).on('turbo:load', function() {
	$("div[id^='show_counter']").hide();
	$("div[id^='reply_counter']").hide();
	$("div[id$='_extras']").hide();

	$('a[disabled=disabled]').click(() => event.preventDefault());

	$("a[id^='counter_']").click(function() {
		let regexp = $(this).attr('id').match(/^counter_\w{4}(\d+)$/);
		let num = regexp[1];
		let details = `div[id$='_counter${num}']`
		let extras = `div[id$='${num}_extras']`
		let hide_link = `#counter_hide${num}`
		let show_link = `#counter_show${num}`
		$(this).toggle();
		$(details).toggle();
		$(extras).toggle();
		if ($(this).html().match(/View/)) {
			$(hide_link).toggle();
		} else {
			$(show_link).toggle();
		}
		event.preventDefault();		
	});
});
