import { Controller } from "@hotwired/stimulus"
import "leaflet/dist/leaflet.css"
import markerIcon from "leaflet/dist/images/marker-icon.png";
import marker from "leaflet/dist/images/marker-icon.png";
import marker2x from "leaflet/dist/images/marker-icon-2x.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";

export default class extends Controller {
  static targets = [ "mapContainer" ]
  static values = {
    lat: Number,
    lon: Number,
    info: String,
    zoomLevel: Number,
  }
  
  connect(){
    import("leaflet").then( L => {
      this.map = L.map(this.mapContainerTarget,{zoomDelta:0.5,zoomSnap:0.5}).setView([ this.latValue, this.lonValue ], this.zoomLevelValue);
      
      delete L.Icon.Default.prototype._getIconUrl;

      L.Icon.Default.mergeOptions({
          iconRetinaUrl: marker2x,
          iconUrl: marker,
          shadowUrl: markerShadow
      });
      //L.Marker.prototype.setIcon(L.icon({
      //  iconUrl: markerIcon
      //}));
      L.marker([ this.latValue, this.lonValue ], {title: this.infoValue}).addTo(this.map);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(this.map);
    });
  }

  disconnect(){
    this.map.remove()
  }
}
