import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "email", "toggler", "opener", "closer", "indicator"]

  //static values = { showIf: String }
  connect() {
    this.hide_inputs();
    if (this.hasEmailTarget) {
      this.currentEmail = this.emailTarget.value;
      this.closerText = this.closerTarget.innerText;
      this.boundPassword = this.needs_password.bind(this)
      this.emailTarget.addEventListener("blur", this.boundPassword);
    }
  }

  toggle(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    
    this.inputTargets.forEach((t) => t.classList.toggle('d-none'))
    this.togglerTarget.classList.toggle('d-none');
    if (this.hasIndicatorTarget) {
        this.indicatorTarget.classList.toggle('fa-angle-down', 'fa-angle-up');
    }
    if (this.hasCloserTarget && (this.closerTarget.innerText != this.closerText)) {
        this.closerTarget.innerText = this.closerText;
        this.emailTarget.value = this.currentEmail;
    }
  }
  
  needs_password(event){
    console.log(event.target)
    if ((this.currentEmail != event.target.value) && !this.togglerTarget.classList.contains('d-none')) {
      this.openerTarget.click();
      this.closerTarget.innerText = 'Keep Current Password';
    }
  }

  hide_inputs(event){
    this.inputTargets.forEach((t) => t.classList.add('d-none'));    
  }
}