$(document).on('turbo:load', function() {
	 $('#hire-modal').show();

	//let brand_default = $('[id$="equip_brand"]').find('option:first-child').clone().wrap('<option>').parent().html();
	//$('[id$="equip_brand"]').html(brand_default);
	//let model_default = $('[id$="_id"]').find('option:first-child').clone().wrap('<option>').parent().html();
	//$('[id$="_id"]').html(model_default);

	$("input[id*='_except_']").click(function() {
		let hour = $(this).closest("tr").find("select[id*='biz_hour']");
		/*
		let index = hour.attr('id').match(/(\d*)$/);
		let target = $(this).closest("tr").find("input[type='text']");
		let target_field = $(this).closest("tr").find("input[type='hidden']");
		*/
		if ($(this).prop("checked") == true) {
			/*
			$(target).each(function(){
				//console.log($(this).flatpickr()); 
				$(this).flatpickr().clear();
			})
			*/
			$(hour).each(function(){ 
				//$(this).prop('value','');
				$(this).prop('selectedIndex',0);
			})
		}
	});
	
	$("select[id*='biz_hour']").change(function() {
		let except_check = $(this).closest("tr").find("input[type='checkbox']");
		console.log(except_check);
		if (except_check.prop("checked") == true || except_check.prop("selected") == true) {
			except_check.prop("checked",false);
			except_check.prop("selected",false);
		}
	});

	$("form[id*='booking']").on('click', "input[name='commit']", function(event) {
		event.preventDefault();
		let timestamp = $("input[id$='datetime']").val();
		let target = $("input[id$='start_time']");
		console.log('Datestamp is:'+timestamp);
		target.val(timestamp);
		console.log('Timestamp is:'+target.val());
		$("form[id*='booking']").submit();
	});
});
