import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["switch", "display", "label"]
  static values = {
    inactive: String,
    active: String
  }

  disconnect() {
    this.toggle()
  }

  toggle(event) {
    //event.preventDefault();
    //event.stopImmediatePropagation();
    
    this.displayTargets.forEach((t) => t.classList.toggle('d-none'))
    this.labelTargets.forEach((l) => {
        //if (l.classList.contains(this.inactiveValue)) {
            l.classList.toggle(this.inactiveValue.toString())
            l.classList.toggle(this.activeValue.toString())
            
        //} else {
          
        //}
    })
  } 


}