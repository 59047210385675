import { Controller } from "@hotwired/stimulus"
import zipToTz from 'zip-to-tz';

// Connects to data-controller="set-timezone"
export default class extends Controller {
  static targets = ["zipField", "tzField"];
  
  connect() {
    console.log("TZ setter ready");
    console.log(this.zipFieldTarget.id);
  }
  
  set_tz(){
    console.log(this.zipFieldTarget.value.length);
    if (this.zipFieldTarget.value.length == 5) {
      const tz = zipToTz(this.zipFieldTarget.value.toString());
      this.tzFieldTarget.value = tz;
    }
    console.log(this.tzFieldTarget.value);
  }
}
