import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["field", "nested", "indicator"]
  static classes = ['active','inactive','hidden']
  
  initialize() {
    this.fieldTargets.forEach((t) => t.classList.add(this.hiddenClass))
    this.nestedTargets.forEach((x) => {
        document.getElementById(x.dataset.focus).classList.toggle(this.hiddenClass)
        document.getElementById(x.dataset.indicator).classList.replace(this.inactiveClass, this.activeClass)
    })
  }

  toggle(event) {
    console.log(`${event.target.id} : ${event.target.dataset.focus}`)
    this.fieldTargets.forEach((t) => {
        if (t.id == event.target.dataset.focus) {
            t.classList.toggle(this.hiddenClass)
        }
    })
    
    this.indicatorTargets.forEach((i) => {
        if (i.id == event.target.dataset.indicator) {
            if(i.classList.contains(this.inactiveClass)) {
              i.classList.replace(this.inactiveClass, this.activeClass);
            } else {
              i.classList.replace(this.activeClass, this.inactiveClass);
            }
        }
    })
  } 


}