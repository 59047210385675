import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["add_item", "template", "delete_key", "new_record"]

  add_association(event) {
    event.preventDefault()
    let item = event.target.closest("[id$='_list']")
    //console.log(item)
    let len = item.querySelectorAll("[id*='_destroy']").length
    let index = (len > 0) ? len : 0
    let content = this.templateTarget.innerHTML.replace(/SPEC_INDEX/g, index)
    this.add_itemTarget.insertAdjacentHTML("beforebegin", content)
    
    let select_ids = [...this.new_recordTargets].pop().querySelectorAll("[id^='equip_']")
    console.log([...this.new_recordTargets].pop().querySelectorAll("[id^='equip_']"))
    for (let el of select_ids) {
        el.id = `${el.id}_${index}`
    }
    //document.getElementById('add_equip').insertAdjacentHTML("beforebegin", content)
  }

  remove_association(event) {
    event.preventDefault()
    let item = event.target.closest(".nested-fields")
    let opt = document.getElementById(item.querySelectorAll("[id*='_destroy']").item(0).id)
    
    if(window.confirm(`Remove this ${this.data.get("assoc")}?`)) {
        if (opt.dataset.nestedFormTarget.match(/delete-key/)) {
            opt.value = true
            if(item.hasAttribute('data-target')) {
                item.remove()       
            } else {    
                item.style.display = 'none'
            }
        }
    } 
  }

}