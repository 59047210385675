$(document).on('turbo:load', function() {
	const userLang = navigator.language || navigator.userLanguage;
	
	if (!$("#hidden_fields").find("#artist_id").is(':disabled')) {
		$("#hidden_fields").find("#artist_id").prop("disabled", "disabled");
		$("#hidden_fields").find("#sponsor_id").prop("disabled", false);
	}
	
	$("[id$='-icon']").mouseover(function() {
		this.style.cursor = "pointer";
	});
	
	$("[id$='-icon']").each(function() {
		let str = $(this).attr('id').match(/(.+)-icon/)[1];
		var target;
		if (str.match(/(\w+)-(\d+)/)) {
			let key = str.match(/(.+)-(\d+)/);
			target = `#${key[1].replace(/-/gi, '_')}_${key[2]}`;
			
			if($(target).is(":checked")) {
				$(this).css('border-color','#05f0d0');
				$(this).css('border-width','2px');
			}
		} else {
			target = `#opt_${str}`;
			if($(target).is(":checked")) {
				$("[id$='-icon']").css('border-color', default_border);
				$("[id$='-icon']").css('border-width', default_borderwidth);
				$(this).css('border-color','#05f0d0');
				$(this).css('border-width','2px');
			}
		}
	});
	
	$("[id$='-icon']").click(function(event) {
		const default_border = 'rgba(0,0,0,0.125)';
		const default_borderwidth = '1px';
		let click_target = $(this);
		let type = click_target.attr('id').match(/(.+)-icon/)[1];
		var target;
		
		if (type.match(/(\w+)-(\d+)/)) {
			let key = type.match(/(.+)-(\d+)/);
			target = `#${key[1].replace(/-/gi, '_')}_${key[2]}`;
			
			if($(target).is(":checked")) {
				$(target).removeAttr('checked');
				click_target.css('border-color', default_border);
				click_target.css('border-width', default_borderwidth);
				if(key[1].match(/^subscription$/)){
					$("[id^='subscription-venue']").css('border-color',default_border);
					$("[id^='subscription-venue']").css('border-width',default_borderwidth);
					$("[id^='subscription_venue']").removeAttr('checked');
				}
			} else {
				if(key[1].match(/^subscription$/)){
					$("[name='subscription']").removeAttr('checked');
					$("[id^='subscription'][id$='-icon']").css('border-color', default_border);
					$("[id^='subscription'][id$='-icon']").css('border-width', default_borderwidth);
					if(parseInt(key[2]) > 1000) {
						$("[id^='subscription-venue']").css('border-color','#05f0d0');
						$("[id^='subscription-venue']").css('border-width','2px');
						$("[id^='subscription_venue']").attr('checked','checked');
					} else {
						$("[id^='subscription-venue']").css('border-color',default_border);
						$("[id^='subscription-venue']").css('border-width',default_borderwidth);
						$("[id^='subscription_venue']").removeAttr('checked');
					}
				}
				click_target.css('border-color','#05f0d0');
				click_target.css('border-width','2px');
				$(target).attr('checked','checked');
			}
		} else {
			$('#query-fields').find('input').attr('value','');
			$('#query-fields').find('option').removeAttr('selected');
			// Array syntax to convert jQuery element to JavaScript object & enable reset method
			$('#global_seek')[0].reset();
			
			target = `#opt_${type}`;
			let search_all = "#finder_search_all";
			$(search_all).removeClass('d-block').addClass('d-none');
			
			$("[id$='-icon']").css('border-color', default_border);
			$("[id$='-icon']").css('border-width', default_borderwidth);
			$(this).css('border-color','#05f0d0');
			$(this).css('border-width','2px');
		
			$(target).trigger('change');
			$(target).prop('checked','true');
			if(type.match(/artist|venue/)){
				$(search_all).text(`Search All ${type.charAt(0).toUpperCase() + type.slice(1)}s`);
				$(search_all).attr('href',`${type}s`);
				$(search_all).removeClass('d-none').addClass('d-block');
			}
			
			if ($('#query-fields').hasClass('d-none')) {
				$('#query-fields').animate({height: ['toggle','swing']}, 500).removeClass('d-none'); //expand hidden query fields
			}
		} 
		
		//console.log(target);
		//console.log(type.match(/(.+)-(\d+)/));
		//console.log(`Target checked?: ${$(target).is(":checked")}`);
		
	});
	
	$("#opt_artist").on('change', function() {
		//console.log('Button clicked');
		$("#artist_id").prop('disabled','disabled');
		$("#sponsor_id").prop('disabled',false);
		$('#venue_type_pick').hide();
		$('#capacity_pick').hide();
		$('#artist_type_pick').show();
		$('#specialty_pick').show();
		$("#wrapper").offset({top:$("#masthead").height()});
		$("#finder_fields_submit").prop('value', "Find Artists");
	});
		
	$("#opt_sponsor").on('change', function() {
		//console.log('Button clicked');
		$("#artist_id").prop('disabled',false);
		$("#sponsor_id").prop('disabled','disabled');
		$('#venue_type_pick').hide();
		$('#capacity_pick').hide();
		$('#artist_type_pick').show();
		$('#specialty_pick').show();
		$("#wrapper").offset({top:$("#masthead").height()});
		$("#finder_fields_submit").prop('value', "Find Gigs");
	});
		
	$("#opt_venue").on('change', function() {
		//console.log('Button clicked');
		$("#artist_id").prop('disabled',false);
		$("#sponsor_id").prop('disabled',false);
		$('#venue_type_pick').show();
		$('#capacity_pick').show();
		$('#artist_type_pick').hide();
		$('#specialty_pick').hide();
		$("#wrapper").offset({top:$("#masthead").height()});
		$("#finder_fields_submit").prop('value', "Find Venues");
	});
		
	let specs_single = $("select[id^='specialty']").html();

	$('[id$="artist_type"]').change(function(event) {
		let i = $(event.target).val();
		let list = $(event.target).closest('form').find("select[id*='specialty']"); 
		let vocals = "<option value='alto vocals'>Alto Vocals</option><option value='baritone vocals'>Baritone Vocals</option><option value='bass vocals'>Bass Vocals</option><option value='contralto vocals'>Contralto Vocals</option><option value='countertenor vocals'>Countertenor Vocals</option><option value='mezzo-soprano vocals'>Mezzo-Soprano Vocals</option><option value='soprano vocals'>Soprano Vocals</option><option value='tenor vocals'>Tenor Vocals</option>";
		let djstyles = "<option value='CDJ'>CDJ</option><option value='serato DJ'>Serato DJ</option><option value='vinyl DJ'>Vinyl DJ</option>";
		let cta;

		if (list.attr('multiple') != 'multiple') {
			cta = "<option value=''>Choose Specialty</option>";
			if (i.match(/DJ/)) {
				list.html(cta+djstyles);
			} else if (i.match(/Singer/)) {
				list.html(cta+vocals);
			} else {
				list.html(specs_single);
			}
			list.prop('selectedIndex',0);
		}
	});
	
	$('[id^="clear_"]').click(function() {
	    console.log($(this).prop('id'));
	    let target = $(this).closest('div.input-group').find("input[id$='picker']");
	    $(target).flatpickr().clear();
    });

	// Require artist or show option to be selected before submitting search criteria 
	$('#finder_fields_submit').click( function() {
		if ($('#venue_type_pick').is(':visible')) {
			$('#genre_ask').attr('required',false);
		} else {
			$('#genre_ask').attr('required',true);
		}
        if (!$.isNumeric(parseInt($('#zip_code').val()))) {
        	$('#zip_code').addClass('is-invalid');
        } else {
        	$('#zip_code').removeClass('is-invalid');
        }
	});
	
	$('#chooser_form_submit').click( function() {
		if ($('#gig_ask[pct_type]').val()) {
			$('#gig_ask[pct]').attr('required',false);
		} else {
			$('#gig_ask[pct]').attr('required',true);
		}
	});
	
	// Enable postponed booking agreement to be submitted only if the client acceptance check box is checked
	$('#client_acceptance').click( function() {
		let btn = $('input[value="Accept Postponement"]')
		if (btn.prop('disabled')) {
			btn.prop('disabled',false);
		} else {
			btn.prop('disabled',true);
		}
	});
	
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    let forms = $('.needs-validation');
    
    // Loop over forms with Bootstrap validation and prevent submission
    Array.from($('.needs-validation'))
    .forEach(function (form) {
    	form.addEventListener('submit', function (event) {
    		let formInputs = form.querySelectorAll(".form-control");    
		    
		    for(var i = 0; i < formInputs.length; ++i) {
		    	var input = formInputs[i];
		    	// Validate input fields in a form with Bootstrap validation
		    	if( input.hasAttribute('required') ) {
		    		if(input.value.trim() === '') {
		    			input.classList.remove('is-valid');
		    			input.classList.add('is-invalid');
		    			$(input).closest('.row').find('.invalid-feedback').addClass('d-block');
					}
					else {
						input.classList.remove('is-invalid');
						input.classList.add('is-valid');
		    			$(input).closest('.row').find('.invalid-feedback').removeClass('d-block');
						//return true;
					}
			    }
			    else {
			      // if we get here, then any other inputs not marked as 'required' are valid
			      input.classList.add('is-valid');
			    }
		    }
		    
    		if (!form.checkValidity()) {
    			event.preventDefault();
    			event.stopPropagation();
	        } 
	        form.classList.add('was-validated');
        }, false)
    });

	$('a[disabled=disabled]').click(() => event.preventDefault());

	$('div.awesomplete').addClass('col-sm-9');
	$('input.awesomplete').addClass('form-control').prop('placeholder','Enter venue name');
	return $('input.awesomplete').addClass('form-control').data('minchars','2').data('maxitems','20');
	
	/*$('#seeker_list').append("<%= j(render 'artist_list')%>");
	$('#div_next_link').html("<%= j(render 'next_link') %>");


	const loadNextPage = function(){
		if ($('#next_link').data("loading")){ return }  // prevent multiple loading
		var wBottom  = $(window).scrollTop() + $(window).height();
		var elBottom = $('#seeker_list').offset().top + $('#seeker_list').height();
		if (wBottom > elBottom){
			$('#next_link')[0].click();
			$('#next_link').data("loading", true);
		}
	};

	window.addEventListener('resize', loadNextPage);
	window.addEventListener('scroll', loadNextPage);
	window.addEventListener('load',   loadNextPage);*/
});