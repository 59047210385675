import { Controller } from "@hotwired/stimulus";
import {loadConnectAndInitialize} from '@stripe/connect-js';
import { get, post, put, patch, destroy } from '@rails/request.js';

export default class extends Controller {
    static targets = [ 'submit', 'container', 'spinner']
    static values = {
    	userId: String, 
    	url: String,
    	key: String,
    }
    
    connect() {
        if (this.hasContainerTarget) {
        	console.log('Stripe Connect Verification Container Embedded');
			const fetchClientSecret = async () => {
				// Fetch the AccountSession client secret
				const response = await post(this.urlValue, { 
					body: JSON.stringify({
			  			user_id: this.userIdValue,
					}),
					contentType: "application/json",
				});
				if (!response.ok) {
					// Handle errors on the client side here
				    const {error} = await response.json;
				    console.error('An error occurred: ', error);
				    document.querySelector('#stripe_connect_error').removeAttribute('hidden');
				    document.querySelector('#stripe_connect_error').innerHTML(`An error occurred: ${error}`);
				    return undefined;
			    } else {
				    const {client_secret: clientSecret} = await response.json;
				    document.querySelector('#stripe_connect_error').setAttribute('hidden', '');
				    return clientSecret;
			    }
		    }
		    
		    const stripeConnectInstance = loadConnectAndInitialize({
			    publishableKey: this.keyValue,
			    fetchClientSecret: fetchClientSecret,
		    });
			const onboardingComponent = stripeConnectInstance.create("account-onboarding");
			this.containerTarget.appendChild(onboardingComponent);
			
			onboardingComponent.setOnExit(() => {
				console.log('User exited the onboarding flow');
				const link = document.getElementById("stripe_connect_update").innerHTML;
				this.containerTarget.insertAdjacentHTML("afterend", link);
			});
	    }
    }
    
    displayContainer(event) {
    	if (window.getComputedStyle(this.containerTarget).display === "none") {
    		//console.log(`Target: Stripe Connect Verification Container Display: ${window.getComputedStyle(this.containerTarget).display}`);
    		this.spinnerTarget.style.display = 'none';
    		this.containerTarget.style.display = 'block';
    	}
    }
    
    async verify(event) {
    	event.preventDefault();
    	this.spinnerTarget.classList.toggle('d-none');
    	
    	console.log("Stripe verification link clicked")
    
		let url = this.submitTarget.dataset.url;
		let user_id = this.userIdValue;
		let verify_type = this.submitTarget.dataset.verifyType;
		
		// Process the PaymentIntent		
    	const res = await post(url, {
		    body: JSON.stringify({
	  			user_id: user_id, 
	  			verify_type: verify_type,
			}),
			contentType: "application/json",
			responseKind: 'json', 
  		})
  		
  		if (!res.ok) {
			// Handle errors on the client side here
		    this.spinnerTarget.classList.toggle('d-none');
		    const {error} = await res.json;
		    console.log('An error occurred: ', error);
		    document.querySelector('#stripe_connect_error').removeAttribute('hidden');
		    document.querySelector('#stripe_connect_error').innerHTML(`An error occurred: ${error}`);
		    return undefined;
	    } else {
		    this.spinnerTarget.classList.toggle('d-none');
		    const link = await res.json;
		    console.log(link.url);
		    document.querySelector('#stripe_connect_error').setAttribute('hidden', '');
  			window.location.assign(link.url);
  		}
    }
}