/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

require("@rails/actiontext");
require("trix");
require("@popperjs/core");

import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
//import "channels"

import '@hotwired/turbo-rails';
import $ from "expose-loader?exposes=$,jQuery!jquery";

import { FetchRequest } from '@rails/request.js';
import awesomplete from 'awesomplete';
import AwesompleteJS from 'awesomplete/awesomplete.js';
import L from 'leaflet';
import 'leaflet-defaulticon-compatibility';
import "bootstrap";
// Import the specific Bootstrap modules you may need (Modal, Alert, etc)
import { Tooltip, Popover } from "bootstrap";

import "@fortawesome/fontawesome-free/js/all";
import 'raty-js';
import '../src/js/jquery.raty';
import '../src/js/jquery.validate.min';
import '../src/js/additional-methods.min';
import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";
import flatpickr from "flatpickr";
import { DateTime } from "luxon"; // Javascript module to determine browser's local timezone (and more)
import zipToTz from 'zip-to-tz';
import ahoy from "ahoy.js"; // JS module for Ahoy first-party analytics API

//Legacy script & css files
import '../src/js/global';
import '../src/js/spotlight';
import '../src/js/demos';
import '../src/js/sponsors';
import '../src/js/users';
import '../src/js/venues';
import '../src/js/modals';


// JS manifest of images
const images = require.context('../images/', true);
const imagePath = (name) => images(name, true);

// CSS manifest
import '../stylesheets/application.scss';
import 'animate.css';

const context = require.context("../controllers", true, /\.js$/);
const Stimulus = Application.start();

Stimulus.load(definitionsFromContext(context));
Rails.start();
ActiveStorage.start();

//Activate Bootstrap tooltips & popovers
document.addEventListener("DOMContentLoaded", function(event) {
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  		return new Tooltip(tooltipTriggerEl);
  });
  	
  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
  		return new Popover(popoverTriggerEl);
  });
});

// Facebook Login global init
// Initialize FB SDK with your app and the Graph API version for your app
(function(d, s, id){
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {return;}
    js = d.createElement(s); js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'facebook-jssdk')
);

window.fbAsyncInit = function() {
    FB.init({
              appId            : '455566814567577',
              xfbml            : true,
              version          : 'v19.0',
              cookie           : true,   
            });
};

document.dispatchEvent(new CustomEvent('facebook_sdk:ready', { 
  detail: { facebook_sdk: window.fbAsyncInit },
  cancelable: false
}));

// Stripe iframe persistence
// Reference: https://github.com/hotwired/turbo/issues/270#issuecomment-1068130327
let stripeIFrameQuery = 'iframe[src^="https://js.stripe.com"]';
document.addEventListener('turbo:before-render', function (event) {
  const stripeIFrame = document.querySelector(stripeIFrameQuery);
  const newStripeIFrame = event.detail.newBody.querySelector(stripeIFrameQuery);
  if (stripeIFrame && !newStripeIFrame){
    event.detail.newBody.appendChild(stripeIFrame);
  }
});

document.addEventListener("turbo:load", () => {
  if (!sessionStorage.getItem('timezone')) {
        var tz = DateTime.local().toFormat('z') || 'UTC';
        sessionStorage.setItem('timezone', tz);
  }
  var currTz = sessionStorage.getItem('timezone');
  //console.log(`Local Timezone : ${currTz}`);
  var zone_inputs = document.querySelectorAll("input[id$='local_tz']");
  for(let i = 0; i < [...zone_inputs].length; i++) {
      zone_inputs[i].setAttribute('value', currTz);
      //console.log(`Timezone input value : ${zone_inputs[i].getAttribute('value')}`);
  }
});

document.addEventListener('turbo:before-fetch-request', (event) => {
  // Turbo Drive does not send a referrer like turbolinks used to, so let's simulate it here
  event.detail.fetchOptions.headers['Turbo-Referrer'] = window.location.href;
  event.detail.fetchOptions.headers['X-Turbo-Nonce'] = $("meta[name='csp-nonce']").prop('content');
});

document.addEventListener("turbo:before-cache", function() {
  let scriptTagsToAddNonces = document.querySelectorAll("script[nonce]");
  for (var element of scriptTagsToAddNonces) {
    element.setAttribute('nonce', element.nonce);
  }
});

document.addEventListener("turbo:frame-missing", (event) => {
  const { detail: { response, visit } } = event;
  event.preventDefault();
  visit(response);
});

// Google Places API integration
function initAutocomplete(...args) {
	const event = new Event("places-loaded", { bubbles: true, cancelable: true });
	event.args = args;
	window.dispatchEvent(event);
}

window.initAutocomplete = initAutocomplete;
