import { Controller } from "@hotwired/stimulus"
import { get, post } from '@rails/request.js'
import TomSelect from "tom-select";

export default class extends Controller {
  // Define static value to fetch the URL from HTML data attributes.
  static values = { 
      url: String,
  };

  // Triggered when the Stimulus controller is connected to the DOM.
  connect() {
    this.initializeTomSelect();
  }

  // Triggered when the Stimulus controller is removed from the DOM.
  disconnect() {
    this.destroyTomSelect();
  }

  // Initialize the TomSelect dropdown with the desired configurations.
  initializeTomSelect() {
    // Return early if no element is associated with the controller.
    if (!this.element) return;
    
    const tomSelectElement = this.element; // The HTML select element

    // Define custom rendering for dropdown options.
    // see: https://tom-select.js.org/examples/customization/
    const renderOption = (data, escape) => {
      return `
        <div>
          <span class="d-block fw-bold">${escape(data.name)}</span>
          <span class="text-secondary">${escape(data.loc)}</span>
        </div>
      `;
    };

    // Create a new TomSelect instance with the specified configuration.
    // see: https://tom-select.js.org/docs/
    // value, label, search, placeholder, etc can all be passed as static values instead of hard-coded.
    this.select = new TomSelect(tomSelectElement, {
      valueField: 'name',
      labelField: 'name',
      searchField: 'name',
      maxItems: 1,
      placeholder: "Select location",
      closeAfterSelect: true,
      preload: true,
      create: true,
      openOnFocus: true,
      highlight: false,
      sortField: { field: "name", direction: "asc" },
      load: this.fetchData.bind(this),
      render: {
          option: renderOption
      }
    });
  }
  
  // Request.js fetchData syntax (ChatGPT generated)
  async fetchData(search, callback) {
    // Construct URL for fetching data which comes from the static value.
    // https://tom-select.js.org/examples/remote/
    const url = `${this.urlValue}?query=${encodeURIComponent(search)}`;
    
    try {
      const response = await get(url, {
        headers: { 'Accept': 'application/json' },
        responseKind: 'json'
      })
      
      if (response.ok) {
        const data = await response.json;  // Convert response to JSON.
        callback(data);                      // Pass data to TomSelect.
      } else {
        callback();  // Handle non-200 HTTP responses by passing an empty result set.
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      callback();  // Handle any errors by passing an empty result set.
    }
  }

  // Cleanup: Destroy the TomSelect instance when the controller is disconnected.
  destroyTomSelect() {
    if (this.select) {
      this.select.destroy();
    }
  }
}

