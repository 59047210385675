import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static values = {
    url:String()
  }
  change(event) {
    let idx = event.target.id.match(/\d+/)[0] 
    console.log(idx)
    let inputs = document.querySelectorAll(`select[id$='_${idx}']`)
    console.log(inputs)
    let attrs = [...inputs].map(list => {
        return `${list.name}=${(list.selectedIndex > 0) ? encodeURIComponent(list.selectedOptions[0].value) : ""}`
    })
    let query = attrs.join('&')
    console.log(query)
    let target = document.getElementById(`venue_techspecs_attributes_${idx}_id`)
    
    get(`${this.urlValue}?${query}&idx=${idx}`, {
          responseKind: 'turbo-stream'
    })
  }
}