import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["add_item", "template", "delete_key"]

  add_association(event) {
    event.preventDefault()
    var len = document.querySelectorAll(".nested-fields").length
    var index = (len >= 1) ? len+1 : 1
    var content = this.templateTarget.innerHTML.replace(/SPEC_INDEX/g, index)
    document.getElementById('add_demo').insertAdjacentHTML("beforebegin", content)
  }

  remove_association(event) {
    event.preventDefault()
    let item = event.target.closest(".nested-fields")
    let opt = item.querySelector("[id*='_destroy']")

    if(window.confirm('Remove this demo?')) {
        if (opt.dataset.addDemoTarget.match(/delete-key/)) {
            opt.value = true
            if(item.hasAttribute('data-add-demo-target')) {
                item.remove()       
            } else {    
                item.style.display = 'none'
            }
        }
    } 
  } 


}