import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["form", "pageInput", "submitButton"]

	search() {
	    let pageInput = this.pageInputTarget
	    pageInput.value = 1
	    
	    clearTimeout(this.timeout)
	    this.timeout = setTimeout(() => {
	    	this.formTarget.requestSubmit()
	    }, 200)
    }
    
    reset(event){
    	this.submitButtonTarget.disabled = false;
	}
}