import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["form", "input","error"]
  
    connect() {
        this.inputTarget.value = ''
        this.inputTarget.setAttribute('value','')
        this.boundCheck = this.check.bind(this)
        this.boundClear = this.clear.bind(this)
        this.inputTarget.addEventListener('change', this.boundCheck)
        this.inputTarget.addEventListener('beforeinput', this.boundClear)
        this.inputTarget.addEventListener('input', this.boundClear)
        this.formTarget.addEventListener('submit', this.boundCheck)
    }
    
    disconnect() {
        this.formTarget.removeEventListener("submit", this.boundCheck)
        this.inputTarget.removeEventListener("blur", this.boundCheck)
        this.inputTarget.removeEventListener('beforeinput', this.boundClear)
        this.inputTarget.removeEventListener('input', this.boundClear)
    }
    
    clear(event) {
        if(this.errorTarget.classList.contains('d-block')){
            this.errorTarget.classList = 'alert alert-danger animate__animated animate__fadeOut'
        }
    }
    
    check(event) {
        var validRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
        //var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        //var validRegex = /^[\\w!#$%&'*+/=?`{|}~^-]+(?:\\.[\\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,6}$/
        
        if (this.inputTarget.value.match(validRegex)) {
            return true
        } else {
            this.errorTarget.innerHTML = "Please include '@' and hostname in email"
            this.errorTarget.classList = 'animate__animated animate__fadeIn alert alert-danger'
            //this.errorTarget.classList.remove('d-none')
            this.errorTarget.classList.add('d-block')
            event.preventDefault();
            event.stopImmediatePropagation();
        }
    }
}
