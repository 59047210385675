$(document).on('turbo:load', function() {
//	$('#demo_block').css('display','none');
//	$('#demo_submit').css('display','none');
	
	return $("[id$='demoloader']").click(function() {
		const site = $(this).prop('id').substring(0,$(this).prop('id').indexOf('-demoloader'));
		if (site === 'instagram') {
			$("[class$='-addon']").html(`https://${site}.com/p/`);
		} else {
			$("[class$='-addon']").html(`https://${site}.com/`);
		}

		$('#demos_site').prop('value',site);
		
//		$('#demo_block').show();
//		$('#demo_submit').show();
//		return event.preventDefault();
	});

	return $("[id$='unloader']").click(function() {
		$('#demo_block').toggle();
		$('#demo_submit').toggle();
		return event.preventDefault();
	});
});
