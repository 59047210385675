import { Controller } from "@hotwired/stimulus";
import { Turbo } from '@hotwired/turbo-rails';
import { get, post, put, patch, destroy } from '@rails/request.js';

export default class extends Controller {
    static targets = [ 'frame']
/*    static values = {
    	userId: String, 
    	url: String,
    	key: String,
    }
*/    
    async optin(event) {
    	event.preventDefault();
    	
    	console.log("Stripe verification link clicked")
    	console.log(`Posting to: ${event.target.dataset.url}`)
    	
    	//this.frameTarget.src = event.target.dataset.url
    	const response = await post(event.target.dataset.url, { 
    		body: JSON.stringify({
	  			user_id: event.target.dataset.user_id,
			}),
			responseKind: 'turbo-stream', 
    	})
    	//.then(html => Turbo.renderStreamMessage(html))
    	return response.status === 204 //Source: https://www.ducktypelabs.com/how-to-fetch-a-turbo-stream/
    
    	/*async () => {
    		const response = await post(event.target.dataset.url, { 
				body: JSON.stringify({
		  			user_id: event.target.dataset.user_id,
				}),
				responseKind: 'turbo-stream',
			});
			if (!response.ok) {
				// Handle errors on the client side here
			    const {error} = await response.json;
			    console.error('An error occurred: ', error);
			    //document.querySelector('#stripe_connect_error').removeAttribute('hidden');
			    document.querySelector('#stripe_connect_error').innerHTML(`An error occurred: ${error}`);
			    return undefined;
		    } else {
			    Turbo.renderStreamMessage(response.body)
		    }
    	}*/
    }
}