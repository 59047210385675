import { Controller } from "@hotwired/stimulus"
//import { get, post } from '@rails/request.js'
import TomSelect from "tom-select";

export default class extends Controller {
  // Define static value to fetch the URL from HTML data attributes.
  static values = { 
      maxItems: { type: Number, default: 1 },
      plugins: String,
  };

  // Triggered when the Stimulus controller is connected to the DOM.
  connect() {
    this.initializeTomSelect();
  }

  // Triggered when the Stimulus controller is removed from the DOM.
  disconnect() {
    this.destroyTomSelect();
  }

  // Initialize the TomSelect dropdown with the desired configurations.
  initializeTomSelect() {
    // Return early if no element is associated with the controller.
    if (!this.element) return;
    
    const tomSelectElement = this.element; // The HTML select element

    // Create a new TomSelect instance with the specified configuration.
    // see: https://tom-select.js.org/docs/
    // value, label, search, placeholder, etc can all be passed as static values instead of hard-coded.
    this.select = new TomSelect(tomSelectElement, {
      plugins: (this.hasPluginsValue ? this.pluginsValue.split(',') : []), 
      maxItems: this.maxItemsValue,
      //placeholder: "Select location",
      create: false,
      openOnFocus: true,
      highlight: false,
      sortField: 'text',
    });
  }
  
  // Cleanup: Destroy the TomSelect instance when the controller is disconnected.
  destroyTomSelect() {
    if (this.select) {
      this.select.destroy();
    }
  }
}

