import { Controller } from '@hotwired/stimulus'
import flatpickr from 'flatpickr'
import { DateTime } from "luxon";

export default class extends Controller {
  static targets = ['input']
  static values = {
    dateFormat: String,
    enableTime: Boolean,
    noCalendar: Boolean,
    //timeFormat: String,
    altFormat: String,
    defaultDate: String,
    minDate: Boolean,
  }

  connect() {
    var dt = DateTime.local();
    this.instance = flatpickr(this.inputTarget, {
      allowInput: true,
      altInput: true,
      altFormat: this.altFormatValue || "M. j, Y h:i K",
      dateFormat: this.dateFormatValue || 'Z',
      enableTime: this.enableTimeValue || false,
      noCalendar: this.noCalendarValue || false,
      defaultDate: this.defaultDateValue || "",
      minDate: this.minDateValue || "",
      //time_24hr: this.timeFormatValue === '24hr',
      //timeFormat: this.timeFormatValue || 'h:i K',
      onOpen: function(selectedDates, dateStr, instance) {
          instance.altInput.readOnly = true;
      },
      onClose: function(selectedDates, dateStr, instance) {
          instance.altInput.readOnly = false;
          instance.altInput.blur();
      },
    });
  }
  
  disconnect() {
    this.instance.destroy();
  }
}
