import { Controller } from "@hotwired/stimulus"
import { get, post } from '@rails/request.js'

export default class extends Controller {
  static targets = ["button"]
  static values = {
    url: String,
    appId: String,
    apiVersion: String,
  }
  
  async login(event) {
      //event.preventDefault();
      // If user is logged in, automatically get user name and email address (public profile information)
      this.origin = window.location.origin
      let redirect_uri = this.urlValue
      this.error_notice = document.getElementById('error_notice')
                  
      console.log(redirect_uri)
      
      await FB.getLoginStatus(function(r){
          if (r.authResponse) {
              //user is already logged in and connected
              console.log('User is already logged into FB')
              console.log(`authResponse is: ${JSON.stringify(r.authResponse)}`)
              window.location.assign(redirect_uri)
          } else {
              //user is not connected to your app or logged out
              console.log('User is not logged into FB')
              //document.addEventListener('facebook_sdk:ready', ()=> {
                  FB.login(function (response) {
                      if (response.authResponse) {
                          console.log('Congrats! User is finally logged into FB!')
                          console.log(`authResponse is: ${JSON.stringify(response.authResponse)}`)
                          window.location.assign(redirect_uri)
                      } else {
                          //user cancelled login or did not grant authorization
                          if (!this.error_notice.classList.contains('d-none')) {
                              this.error_notice.innerHTML = 'Login canceled. Please try again.';
                              this.error_notice.classList.add('d-none');
                          }
                      }
                  }, {scope : 'public_profile,email,user_birthday'});
              ///}
          }
      });
  }
}