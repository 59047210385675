$(document).on('turbo:load', function() {
	async function cancelSubscription(subId) {
		const csrfToken = $("[name='csrf-token']").attr('content');
		const response = await fetch('/subscriptions/cancel', {
	  		method: 'POST',
	  		headers: {
	  			"X-CSRF-Token": csrfToken,
	  			"Content-Type": "application/json"
  			},
	  		body: JSON.stringify({
	  			sub_id: subId,
			}),
		})
		
		if (!response.ok) {
			const message = `An error has occured: ${response.status}`;
			throw new Error(message);
		}
		
		let notice = await response.json();
		return notice;
	}
	
	const window_reloader = (modal) => {
		$(document).on('hidden.bs.modal', modal, function (event) { 
			window.location.reload();
		});
	}
	
	$("a[id^='gig_details_']").click(function(event) {
		let loc = window.location;
		let gig_id = $(this).attr('id').match(/^gig_details_(.+)$/)[1];
		let details = $("#gig_details_template").html();
		
		//programmatically trigger Turbo load
		Turbo.visit(`/gig_asks/${gig_id}/detail`);
		
		$('#gigModalTitle').html($(this).attr('data-title'));
		$('#gig-modal').find($('.modal-body')).html(details);
		
		$('#gig-modal').modal();
		
		event.preventDefault();		
	});

	$("a[id^='cancel_']").click(function() {
		let regexp = $(this).attr('id').match(/^cancel_(.+)/);
		let num = regexp[1];

		let btn = $("[id^='confirm_cancel']");
		btn.prop('id','confirm_cancel_'+num);
		btn.prop('href', $(this).attr('data-path'));
		$('#cancel-modal').find($('.modal-body')).html($(this).attr('data-msg'));
		$('.cancel-form').attr('action', $(this).attr('data-path'));
		$('.cancel-form').find($('input[name="authenticity_token"]')).prop('value', $('meta[name="csrf-token"]').attr("content"));
		$("#cancel-modal").modal();

		event.preventDefault();		
	});

	$("a[id^='delete_']").click(function() {
		let regexp = $(this).attr('id').match(/^delete_(.+)/);
		let num = regexp[1];

		let btn = $("[id^='confirm_delete']");
		btn.prop('value', $(this).html());
		$('#delete-modal').find($('.modal-body')).html($(this).attr('data-msg'));
		$('.delete-form').attr('action', $(this).attr('data-path'));
		$('.delete-form').find($('input[name="authenticity_token"]')).prop('value', $('meta[name="csrf-token"]').attr("content"));
		$("#delete-modal").modal();

		event.preventDefault();		
	});

	$("a[id^='imgmodal_']").click(function() {
		$('#img-modal').find($('.modal-body')).html($(this).attr('data-src'));
		$('#img-modal').modal();
		
		event.preventDefault();		
	});

	$("a[id^='claim_']").click(function() {
		let regexp = $(this).attr('id').match(/^claim_(.+)$/);
		let num = regexp[1];
		
		let btn = $("[id^='confirm_claim']");
		btn.prop('id',`confirm_claim_${num}`);
		$('#claim-modal').find($('.modal-body')).html($(this).attr('data-msg'));
		$('#claim-modal').modal();
		
		event.preventDefault();		
	});

	$("a[id^='transfer_']").click(function() {
		let regexp = $(this).attr('id').match(/^transfer_(.+)$/);
		let num = regexp[1];
		
		let btn = $("[id^='confirm_transfer']");
		btn.prop('id',`confirm_transfer_${num}`);
		$('#transfer-modal').find($('.modal-body')).html($(this).attr('data-msg'));
		$('.transfer-form').attr('action', $(this).attr('data-path'));
		$('.transfer-form').find($('input[name="authenticity_token"]')).prop('value', $('meta[name="csrf-token"]').attr("content"));
		$('#transfer-modal').modal();
		
		event.preventDefault();		
	});

	$("a[id^='subscription_']").click(function() {
		let sub_id = $(this).attr('id').match(/^subscription_(.+)$/)[1];
		let btn = $("#subscription-delete");
		let delta = $("#subscription-update");
		let closer = $("#close-btn");
		
		btn.attr('data-sub-id', sub_id);
		$('#subscription-modal').find($('.modal-body')).html($("#subscription_details").html());
		
		btn.click(function() {
			btn.closest('.modal-footer').prepend("<i id='sub_spinner' class='spinner-border-sm'>&nbsp;</i>");
			cancelSubscription(btn.attr('data-sub-id'))
			.then(data => { btn.closest('.modal-footer').prepend(`<strong class='small text-success'>${data.notice}</strong>`)})
			.then(btn.hide())
			.then(delta.hide())
			.then($('.subscription_status').html("<strong class='text-danger'>Canceled</strong>"))
			.catch(error => { btn.closest('.modal-footer').prepend(`<strong class='small text-danger'>${error.message}</strong>`)});
		});
		
		closer.click(function() {
			window_reloader('#subscription-modal');
		});
		
		$('#subscription-modal').modal();
		event.preventDefault();		
	});
	
	$("a[id^='invoice_']").click(function() {
		$('#invoiceModalTitle').html($(this).attr('data-title'));
		$('#invoice_description').html($(this).attr('data-msg'));
		$('.invoice_duration').html($(this).attr('data-duration'));
		$('.invoice_rate').html($(this).attr('data-rate'));
		$('.invoice_rate_term').html($(this).attr('data-rate-term'));
		$('#invoice_deposit').html($(this).attr('data-deposit'));
		$('#invoice_deposit_date').html($(this).attr('data-deposit-date'));
		$('#invoice_balance').html($(this).attr('data-balance'));
		$('#invoice_payout_date').html($(this).attr('data-payout-date'));
		$('#invoice_pct_type').html($(this).attr('data-pct-type'));
		$('#invoice_pct_amount').html($(this).attr('data-pct-amount'));
		$('#invoice_fee_amount').html($(this).attr('data-fee-amount'));
		$('#invoice_total').html($(this).attr('data-total'));
		if (parseInt($(this).attr('data-pct-amount').replace('$','').replace(/,/g,''),10) > 0) {
			$('.invoice_pct_row').show();
		} else {
			$('.invoice_pct_row').hide();
		}
		if ($(this).attr('data-fee-amount')) {
			$('.invoice_fee_row').show();
		} else {
			$('.invoice_fee_row').hide();
		}
		$('#invoice-modal').modal();
		
		event.preventDefault();		
	});

	$("a[id^='deny_']").click(function() {
		$('#deny-modal').find($('.modal-msg')).html($(this).attr('data-msg'));
		$('.deny-form').attr('action', $(this).attr('data-path'));
		$('.deny-form').find($('input[name="booking_reply[booking_ask_id]"]')).prop('value', $(this).attr('data-booking-ask-id'));
		$('.deny-form').find($('input[name="authenticity_token"]')).prop('value', $('meta[name="csrf-token"]').attr("content"));
		//console.log($('input[name="booking_reply[booking_ask_id]"]').prop('value'));
		$('#deny-modal').modal();
		
		event.preventDefault();		
	});

	/*$("a[id^='ppd_']").click(function() {
		$('#postpone-modal').find($('.modal-msg')).html($(this).attr('data-msg'));
		$('.ppd-form').attr('action', $(this).attr('data-path'));
		$('.ppd-form').attr("method", 'put');
		$('.ppd-form').find($('input[name="booking_reply[booking_ask_id]"]')).prop('value', $(this).attr('data-booking-ask-id'));
		$('.ppd-form').find($('input[name="authenticity_token"]')).prop('value', $('meta[name="csrf-token"]').attr("content"));
		//console.log($('input[name="booking_reply[booking_ask_id]"]').prop('value'));
		$('#postpone-modal').modal();
		
		event.preventDefault();		
	});*/

	$("a[id^='rating_']").click(function() {
		$('#ratings-modal').find($('.modal-msg')).html($(this).attr('data-msg'));
		$("input[id='rateable_id']").prop('value',$(this).attr('data-rateable'));
		$("input[id='resource_id']").prop('value',$(this).attr('data-resource'));
		$("input[id='author_id']").prop('value',$(this).attr('data-author'));
		$('#ratings-modal').modal();
		
		event.preventDefault();		
	});

	$("#rating-form > img").click(function() {
		$("input[id='score']").prop('value',$(this).attr('alt'));

		return event.preventDefault();		
	});
	
	$('#login-modal').on('show.bs.modal', function () {
		$(this).find($('.modal-title')).html($("a[id^='login_']").attr('data-desc'));
		$(this).find($('.modal-body')).html($("a[id^='login_']").attr('data-msg'));
	});
});
