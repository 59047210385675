import { Controller } from "@hotwired/stimulus"
import { useIntersection } from 'stimulus-use'

//let debounce = require('lodash/debounce');
//let throttle = require('lodash/throttle');

export default class extends Controller {
  static targets = ["form", "pageInput"]
  
  options = {
    threshold: 1
  }

  connect() {
    useIntersection(this, this.options)
    /*
    this.submitAndRevertPageNumber = debounce(this.submitAndRevertPageNumber, 350).bind(this)
    this.submitAndRevertPageNumber = throttle(this.submitAndRevertPageNumber, 350).bind(this)
    */
  }

  /*
  appear(entry) {
    //this.element.click()
    this.submitForm()
  }
  
  submitAndRevertPageNumber(event) {
    let pageInput = this.pageInputTarget
    pageInput.value = 1
    this.formTarget.requestSubmit()
  }
  */

  submitForm(event) {
    event.preventDefault()

    let pageNumber = event.target.getAttribute("href").match(/\d+/)[0]
    let klass = event.target.getAttribute("href").match(/(\w+)\W/)[1]
    console.log(`Target: ${klass}-page-${pageNumber}`)
    
    if (pageNumber === "#") {
      // then do nothing
    } else {
      // let's get the form's page input and replace it with the link's page number
      let pageInput = this.pageInputTarget
      pageInput.value = pageNumber

      // and now let's just click the submit button     
      // warning: for safari users who are not using hotwire: you will need to use a polyfill
      // when using requestSubmit. Not to worry, if you are using hotwire - the 
      // polyfill is automatically handled for you.
      this.formTarget.setAttribute('data-turbo-frame', `${klass}-page-${pageNumber}`)
      this.formTarget.requestSubmit()
    }
  }
}