import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";

export default class extends Controller {
  static targets = ["mirror"]
  
  dup(event){
     let origin = event.target._flatpickr
     let mirror = this.mirrorTarget._flatpickr
     
     origin.config.onChange.push(function(dateStr, dateObj) {
        //mirror.set("minDate", dateFormat(dateObj, 'YYYY-MM-DD HH:mm:SS')) 
        mirror.set("minDate", dateObj) 
        mirror.setDate(dateObj)
     })
  }
}
